<template>
  <v-row class="mx-auto">
    <v-col v-for="(plan, index) in field.data[0]" :key="index">
      <v-card class="mx-auto">
        <v-card-title class="primary white--text" :title="index">
          <div class="headerClass">
            {{ index }}
          </div>
        </v-card-title>
        <v-card-text>
          <div v-for="(field, index2) in plan" :key="index2">
            <v-divider
              class="mb-5"
              v-if="index2 > 0 && field.type === 'conta_adiciona'"
            ></v-divider>
            <v-autocomplete
              :class="
                index2 === 0 && field.type === 'conta_adiciona' ? 'mt-5' : ''
              "
              v-model="field.value"
              :items="
                field.options ? field.options : [{ cod_conta: field.value }]
              "
              item-text="cod_conta"
              outlined
              dense
              :readonly="!field.options"
              :label="field.label"
              v-if="!loading && field.type === 'conta_adiciona'"
              :rules="!!field.required ? required : []"
              @change="saveSelected(index, plan)"
            ></v-autocomplete>
            <v-autocomplete
              dense
              outlined
              :label="field.label"
              v-model="field.value"
              :items="field.options ? field.options : [{ code: field.value }]"
              :readonly="!field.options"
              item-text="code"
              v-if="!loading && field.type === 'ddd_adiciona'"
              :rules="!!field.required ? required : []"
              @change="saveSelected(index, plan)"
            >
              <template v-slot:selection="data">
                <template v-if="data.item.code && data.item.region">
                  {{ data.item.code }} - {{ data.item.region }}
                </template>
                <template v-else>
                  {{ data.item.code }}
                </template>
              </template>
              <template v-slot:item="data">
                <template v-if="typeof data.item !== 'object'">
                  <v-list-item-content v-text="data.item"></v-list-item-content>
                </template>
                <template v-else>
                  <v-list-item-content>
                    <v-list-item-title
                      v-html="data.item.code"
                    ></v-list-item-title>
                    <v-list-item-subtitle
                      v-html="data.item.region"
                    ></v-list-item-subtitle>
                  </v-list-item-content>
                </template>
              </template>
            </v-autocomplete>
          </div>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { mapActions } from "vuex";
export default {
  props: ["field"],
  data() {
    return {
      value: [],
      loading: true,
      required: [v => !!v || `O campo ${this.field.label} é obrigarório`]
    };
  },
  async mounted() {
    this.$emit('componentLoading', true);
    this.value = this.field;
    this.loading = false;
    await this.updateSolicitationValidatePlansValues({});
    this.$emit('componentLoading', false);
  },
  methods: {
    ...mapActions("solicitation", ["updateSolicitationValidatePlansValues"]),
    saveSelected(plan_name, plan) {
      const fields = plan.map(item => {
        return {
          name: item.name,
          type: item.type,
          label: item.label,
          value: item.value
        };
      });
      this.updateSolicitationValidatePlansValues({
        fields: fields,
        plan_name: plan_name
      });
    }
  }
};
</script>
<style scoped>
.headerClass {
  white-space: nowrap;
  word-break: normal;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
