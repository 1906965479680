<template>
  <v-card outlined>
    <v-row class="mx-auto">
      <v-col cols="12">
        <div class="subtitle-1">Pacotes</div>
        <v-col cols="3">
          <v-autocomplete
              dense
              outlined
              required
              label="Selecione o tipo do pacote"
              :items="packageTypes.data"
              item-text="label"
              item-value="id"
              return-object
              @change="clickPackageType($event)"
              :disabled="loading"
              :loading="loading"
          >
          </v-autocomplete>
        </v-col>
        <v-col cols="6" v-if="showPackages">
          <v-autocomplete
              dense
              outlined
              required
              v-model="selectedPackage"
              label="Selecione um pacote"
              :items="packages.data"
              item-text="descricao"
              item-value="id"
              return-object
              :filter="customFilterPacote"
              @change="clickPackage($event)"
              :disabled="loadingPackages"
              :loading="loadingPackages"
          >
          </v-autocomplete>
        </v-col>
      </v-col>
      <v-col
        v-for="(pacote, index) in packagesSelect"
        :key="index"
        class="col-sm-12 col-md-6"
      >
        <v-card class="mx-auto" v-if="!loading">
          <v-card-title class="primary white--text" :title="index">
            <div class="headerClass">
              {{ pacote.descricao }}
            </div>
            <v-spacer></v-spacer>
            <v-btn icon dark @click="removePackage(index)">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-title>
          <v-card-text>
            <v-col class="col-sm-12 col-md-12 col-lg-12">
              <v-autocomplete
                v-model="pacote.line"
                :items="lines.data"
                item-text="nr_linha"
                item-value="id_linha"
                return-object
                outlined
                dense
                :label="pacote.label"
                :rules="!!pacote.required ? required : []"
                @change="saveSelected($event, pacote, index)"
                :filter="customFilter"
                :disabled="loadingLines"
                :loading="loadingLines"
              >
                <template v-slot:selection="data">
                  {{ data.item.ddi }} ({{ data.item.ddd }})
                  {{ data.item.nr_linha }} - {{ data.item.nome_completo }}
                </template>
                <template v-slot:item="data">
                  <template>
                    <v-list-item-content>
                      <v-list-item-title>
                        {{ data.item.ddi }} ({{ data.item.ddd }})
                        {{ data.item.nr_linha }}
                      </v-list-item-title>
                      <v-list-item-subtitle
                          v-html="data.item.nome_completo"
                      ></v-list-item-subtitle>
                    </v-list-item-content>
                  </template>
                </template>
              </v-autocomplete>

              <div class="col-md-12" v-if="loading">
                <v-alert
                  border="left"
                  class="text-center"
                  dense
                  type="warning"
                  dark
                >Carregando pacotes da linha!
                </v-alert>
              </div>
              <div class="d-flex">
                <div class="col-md-6">
                  <template v-if="pacote.packages && !loading">
                    <v-alert border="left" color="indigo" dark dense class="mb-0">
                      Pacotes da linha:
                    </v-alert>
                    <v-list>
                      <template v-for="(linePackage, index) in pacote.packages">
                        <v-list-item :key="linePackage.nome">
                          <v-list-item-content>
                            <v-list-item-title :title="linePackage.nome">
                              {{ linePackage.nome }}
                              <v-list-item-subtitle>
                                R$ {{ linePackage.valor_assinatura ? linePackage.valor_assinatura : 'Sem informação' }}
                              </v-list-item-subtitle>
                            </v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                        <v-divider
                            v-if="index < pacote.packages.length - 1"
                            :key="index"
                        ></v-divider>
                      </template>
                    </v-list>
                  </template>
                </div>
                <div class="col-md-6" v-if="pacote.linha_referencia && !loading">
                  <template>
                    <v-alert border="left" color="indigo" dark dense class="mb-0">
                      Linha referência:
                    </v-alert>
                    <v-list>
                      <LineReferenceComponent
                          :label="'Usuário'"
                          :label_value="pacote.linha_referencia.nome_completo ? pacote.linha_referencia.nome_completo : 'Não existe'"
                      />
                        <v-divider />
                      <LineReferenceComponent
                          :label="'Linha'"
                          :label_value="pacote.linha_referencia.ddd ? '('+pacote.linha_referencia.ddd+') '+ pacote.linha_referencia.nr_linha : 'Não existe'"
                      />
                      <v-divider />
                      <LineReferenceComponent
                          :label="'Valor'"
                          :label_value="pacote.linha_referencia.valor_assinatura ? 'R$ '+pacote.linha_referencia.valor_assinatura : 'Não existe'"
                      />
                      <v-divider />
                    </v-list>
                  </template>
                </div>
              </div>
            </v-col>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import {mapGetters, mapActions, mapState, mapMutations} from "vuex";
import LineReferenceComponent from "./LineReferenceComponent.vue";

export default {
  components: {LineReferenceComponent},
  props: ["field"],
  data() {
    return {
      packagesSelect: [],
      selectedPackage: [],
      showPackages: false,
      loadingLines: false,
      loading: true,
      loadingPackages: false,
      required: [v => !!v || `O campo ${this.field.label} é obrigarório`]
    };
  },
  async mounted() {
    this.$emit('componentLoading', true);
    this.packagesSelect = this.field.data;
    await this.loadingFilters().catch(error => {
      this.$swal("Oops...", error.message, "error");
    });
    await this.loadLines().catch(error => {
      this.$swal("Oops...", error.message, "error");
    });

    // insere pacotes existentes
    await this.updateItems(this.packagesSelect);
    this.loading = false;
    this.$emit('componentLoading', false);
  },
  computed: {
    ...mapState("packages", ["packages", "lines", "packageTypes"]),
    ...mapState("solicitation", ["solicitation_validate_packages_values","solicitation_validate_values"]),
    ...mapGetters({
      name: "forms/getItemName",
      linePackages: "packages/getLinePackages",
      linhaReferencia: "packages/getLineReference"
    })
  },
  methods: {
    ...mapActions("solicitation", ["updateSolicitationValidatePackagesValues","setAutomationComponentValues"]),
    ...mapMutations("forms", ["SET_FORM_EMAIL"]),
    ...mapActions("packages", [
      "loadingFilters",
      "loadPackages",
      "loadLines",
      "loadLinePackages",
      "loadLineReference"
    ]),
    async updateItems(packagesSelect) {
      this.loading = true;
      let values_fields = {...this.solicitation_validate_values};
      values_fields.busca_pacote = packagesSelect;
      this.setAutomationComponentValues({
        automation: "busca_pacote",
        data: values_fields
      });
      this.SET_FORM_EMAIL({});
      this.SET_FORM_EMAIL(this.solicitation_validate_values);
      this.loading = false;
    },
    async removePackage(index) {
      this.loading = true;
      this.packagesSelect.splice(index, 1);
      await this.updateItems(this.packagesSelect);
      this.loading = false;
    },
    clickPackage(pacote) {
      this.loadingLines = true;
      let newPackage = {
        valor: pacote.valor_assinatura,
        descricao: pacote.descricao,
        id_historico_assinatura: pacote.id_historico_assinatura,
        linha_referencia: pacote.linha_referencia,
        packages: []
      };

      let mesclar = [];
      for (let i in this.packagesSelect) {
        mesclar = [].concat(mesclar, this.packagesSelect[i]);
      }
      mesclar = [].concat(mesclar, newPackage);
      this.packagesSelect = mesclar;

      this.selectedPackage = [];
      this.loadingLines = false;
    },
    async saveSelected(line, pacoteSelected, index) {
      this.loading = true;
      if (line.id_linha.length) {
        try {
          await Promise.all([
            this.loadLinePackages({ id_linha: line.id_linha }),
            this.loadLineReference({ id_linha: line.id_linha, id_historico_assinatura: pacoteSelected.id_historico_assinatura })
          ]);
        } catch (error) {
          await this.$swal("Oops...", error.message, "error");
        }

        pacoteSelected = {
          descricao: pacoteSelected.descricao,
          id_historico_assinatura: pacoteSelected.id_historico_assinatura,
          linha_referencia: this.linhaReferencia ? this.linhaReferencia : [],
          packages: this.linePackages.data,
          line: line
        };

        this.packagesSelect[index] = pacoteSelected;
      }
      await this.updateItems(this.packagesSelect);
      this.loading = false;
    },
    async clickPackageType(packageType) {
      this.loadingPackages = true;
      this.showPackages = true;
      await this.loadPackages({package_type: packageType.id}).catch(error => {
        this.$swal("Oops...", error.message, "error");
      });
      await this.loadLines().catch(error => {
        this.$swal("Oops...", error.message, "error");
      });
      this.loadingPackages = false;
    },
    customFilter(item, queryText) {
      queryText = queryText.replace(/[\s()\-/]/g, '');
      const regExp = new RegExp(queryText, 'i')
      const phoneNumber = item.ddi + item.ddd + item.nr_linha;
      return (
          regExp.test(phoneNumber) || regExp.test(item.nome_completo.replace(/\s/g, ''))
      );
    },
    customFilterPacote(item, queryText) {
      const regExp = new RegExp(queryText, 'i')
      const descricao = item.descricao;
      return (
          regExp.test(descricao)
      );
    },
  }
};
</script>
<style scoped>
.v-card__text,
.v-card__title {
  word-break: normal; /* maybe !important  */
}
</style>
