<template>
  <div style="display: contents">
  <v-row v-if="field.typeComponent && field.typeComponent === 'package'">
    <CustomSelectPackageComponent
      :options="field.values"
      :showFieldIccid="true"
      :showFieldConta="true"
      :showFieldAparelho="true"
      :desactiveFields="desactiveFields"
      :default_date="field.default_date ? field.default_date : ''"
      :default_hour="field.default_hour ? field.default_hour : ''"
      :page="page"
      :cols="4"
      @updateValues="updateValuesCustomSelectPackageComponent"
    ></CustomSelectPackageComponent>
  </v-row>
  <v-row v-if="field.typeComponent && field.typeComponent === 'plan'">
    <v-col cols="4" v-for="(plan, index) in field.values" :key="index">
      <v-card class="mx-auto">
        <v-card-title class="primary white--text mb-4" :title="plan.plano.name">
          <div class="headerClass">
            {{ plan.plano.name }}
          </div>
        </v-card-title>
        <v-card-text class="col-md-12">
          <v-col class="col-md-12">
            <v-skeleton-loader
              v-if="loading"
              type="card-heading, paragraph"
            ></v-skeleton-loader>
          </v-col>

          <PersonField
            v-if="!loading && plan.pessoa"
            :selectedPerson="plan.pessoa"
            :readonly="desactiveFields"
            @save="saveDataComponent($event, index, 'pessoa')"
          ></PersonField>

          <v-autocomplete
            dense
            outlined
            label="DDD"
            v-model="plan.ddd"
            :items="listDdds"
            :readonly="desactiveFields"
            return-object
            item-text="code"
            v-if="!loading && plan.ddd"
            :rules="objectRule"
            @change="saveSelected(index, plan)"
          >
            <template v-slot:selection="data">
              <template v-if="data.item.code && data.item.region">
                {{ data.item.code }} - {{ data.item.region }}
              </template>
              <template v-else>
                {{ data.item.code }}
              </template>
            </template>
            <template v-slot:item="data">
              <template v-if="typeof data.item !== 'object'">
                <v-list-item-content v-text="data.item"></v-list-item-content>
              </template>
              <template v-else>
                <v-list-item-content>
                  <v-list-item-title
                      v-html="data.item.code"
                  ></v-list-item-title>
                  <v-list-item-subtitle
                      v-html="data.item.region"
                  ></v-list-item-subtitle>
                </v-list-item-content>
              </template>
            </template>
          </v-autocomplete>

          <v-text-field
            dense
            outlined
            v-model="plan.iccid.value"
            :label="plan.iccid.label"
            :readonly="plan.iccid.readonly"
            v-if="!loading && !desactiveComponentNewIccid && plan.customIccid && page === 'validate'"
            @change="saveSelected(index)"
          />

          <IccidField
            v-if="!loading && !desactiveComponentNewIccid && plan.iccid"
            :ddd="plan.ddd.code"
            :chipSelected="plan.iccid"
            :rules="objectRule"
            :readOnlyField="desactiveFields"
            @changeData="saveDataComponent($event, index, 'iccid')"
          ></IccidField>

          <AccountField
            v-if="!loading && plan.conta"
            :selectedAccount="plan.conta"
            :rules="objectRule"
            :readonly="desactiveFields"
            @saveAccount="saveDataComponent($event, index, 'conta')"
          ></AccountField>

          <DeviceField
            v-if="!loading && plan.aparelho"
            :selectedDevice="plan.aparelho"
            :readonly="desactiveFields"
            @save="saveDataComponent($event, index, 'aparelho')"
          ></DeviceField>

          <LineReference
            v-if="!loading && plan.linha_referencia"
            :selectedLine="plan.linha_referencia"
            :readonly="desactiveFields"
            @save="saveDataComponent($event, index, 'linha_referencia')"
          ></LineReference>

          <v-text-field
            v-model="plan.linha_ativar.value"
            :label="plan.linha_ativar.label"
            outlined
            dense
            @change="onChangeFone(index)"
            v-if="!loading && plan.linha_ativar"
            v-mask="mask"
            :rules="telephoneRules[index]"
          />
          <v-divider
            class="mb-5"
            v-if="plan.linha_ativar && page === 'interaction'
            "
          ></v-divider>

          <!-- Data e hora -->
          <SuspendLineDateFields
            :type="field"
            :index="index"
            :default_label_date="'Data de Ativação'"
            :default_label_hour="'Hora de Ativação'"
            :default_date="field.default_date"
            :default_hour="field.default_hour"
            @changeDateValues="updateDate"
            v-if="page === 'interaction' && !loading"
          />
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
  </div>
</template>
<script>
import {mapActions, mapState, mapMutations, mapGetters} from "vuex";
import SuspendLineDateFields from "../customFieldsComponents/SuspendLineDateFields.vue";
import axios from "@/axios.config";
import CustomSelectPackageComponent from "@/components/forms/CustomSelectPackageComponent.vue";
import PersonField from "@/components/fields/PersonField.vue";
import IccidField from "@/components/fields/IccidField.vue";
import AccountField from "@/components/fields/AccountField.vue";
import DeviceField from "@/components/fields/DeviceField.vue";
import LineReference from "@/components/fields/LineReference.vue";

export default {
  components: {
    LineReference,
    DeviceField,
    AccountField,
    IccidField,
    PersonField,
    CustomSelectPackageComponent,
    SuspendLineDateFields },
  props: ["field", "page"],
  data() {
    return {
      mask: ["(",/\d/,/\d/,") ",/\d/,/\d/,/\d/,/\d/,/\d/,"-",/\d/,/\d/,/\d/,/\d/],
      telephoneRules: [],
      value: [],
      loading: true,
      desactiveFields: false,
      default_date: '',
      default_hour: '',
      objectRule: [v => (v && Object.keys(v).length > 0) || 'Obrigatório'],
      imeis: [],
      desactiveComponentNewIccid: false,
      listDdds: [],
      iccids: [],
      saveValid: false
    };
  },
  async mounted() {
    this.$emit('componentLoading', true);
    try {
      if (this.page === 'interaction' || this.page === 'approval') {
        this.desactiveFields = true;
      }
      await Promise.all([
        this.loadPersons(),
        this.loadImeis(),
      ]);
    } catch (error) {
      await this.$swal("Oops...", error.message, "error");
    }
    this.imeis = this.getImeis();
    this.value = this.field;
    this.listDdds = this.field.ddds;
    this.loading = false;
    await this.changeValues();
    this.$emit('componentLoading', false);
  },
  computed: {
    ...mapState("persons", ["persons"]),
    ...mapState("solicitation", ["solicitation_validate_values"])
  },
  methods: {
    ...mapActions("persons", ["loadPersons"]),
    ...mapActions("solicitation", ["setActivateNewLine", "loadImeis", "loadAccountsJoin", "setAutomationComponentValues"]),
    ...mapActions("interaction", ['setAutomationComponentFields']),
    ...mapGetters("solicitation", ["getImeis", "getAccounts"]),
    ...mapMutations("forms", ["SET_FORM_EMAIL"]),
    ...mapMutations({ setSave: "interaction/setSave" }),

    saveDataComponent(data, index, component) {
      this.field.values[index][component] = data;
      this.saveSelected(index);
    },

    async updateValuesCustomSelectPackageComponent(data) {
      this.field.values = data;
      await this.changeValues();
    },

    changeValues: async function () {
      if (this.page !== 'interaction') {
        this.SET_FORM_EMAIL();
        let values_fields = {...this.solicitation_validate_values};
        values_fields.activate_new_line = this.field;
        this.setAutomationComponentValues({
          automation: "activate_new_line",
          data: values_fields
        });
        this.SET_FORM_EMAIL(this.field);
      } else {
        await this.setAutomationComponentFields({
          automation: "activate_new_line",
          data: this.field
        });
      }
    },

    onChangeFone: async function (index) {
      this.telephoneRules[index] = [
        v =>
            /^\([0-9]{2}\) [0-9]?[0-9]{4}-[0-9]{4}$/.test(v) ||
            "Numero de telefone invalido"
      ];

      await this.saveSelected(index);
    },

    fetchChips: async function (ddd, index) {
      this.loading = true
      await axios.get('solicitation/chips/available', {
        params: {ddd}
      })
          .then((res) => {

            if (res.data.success) {
              this.iccids[index] = res.data.data
            }
          })
          .finally(() => {
            this.loading = false
          })
    },

    updateDate(selectedValue) {
      let hourValue = {
        type: "initHour",
        value: selectedValue.finishHour
      };

      let dateValue = {
        type: "initDate",
        value: selectedValue.initialDate
      };

      this.field.values[selectedValue.index]['initDate'] = dateValue;
      this.field.values[selectedValue.index]['initHour'] = hourValue;

      this.validateDate(selectedValue);
      this.updateState(
        this.field.values[selectedValue.index],
        selectedValue.index
      );
    },

    validateDate(selectedValue) {
      const today = new Date();
      const selectedDate = selectedValue.initialDate;

      let formatedSelectedDate = new Date(selectedDate);
      formatedSelectedDate.setHours(formatedSelectedDate.getHours() + 12);

      let formatedToday = today.toLocaleDateString("pt-BR", {
        timeZone: "UTC"
      });

      if (today >= formatedSelectedDate) {
        this.saveValid = true;
        this.setSave(this.saveValid);
      } else {
        this.$swal(
          "Oops..",
          `A data não pode ser maior que ${formatedToday}, mude a data para salvar!`,
          "error"
        );
        this.saveValid = false;
        this.setSave(this.saveValid);
      }
    },

    updateState(valueToUpdate, index) {
      this.setActivateNewLine(this.field.data);
    },

    async saveSelected(index) {
      if (this.page === "interaction") {
        this.setActivateNewLine(this.field.data);
      } else {
        this.SET_FORM_EMAIL(this.field);
        await this.fetchChips(this.field.data[0][index]['ddd'].value, index);
        await this.changeValues();
      }
    }
  }
};
</script>
<style scoped>
.headerClass {
  white-space: nowrap;
  word-break: normal;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
