<template>
  <v-row>
    <v-col cols="12">
      <v-autocomplete
        v-model="value"
        :items="lines.data"
        :label="field.label"
        item-text="nr_linha"
        item-value="id_linha"
        @change="saveValueText"
        outlined
        dense
        clearable
        return-object
        :filter="filterLine"
      >
        <template v-slot:selection="data">
          {{ data.item.ddi }} ({{ data.item.ddd }})
          {{ data.item.nr_linha }} - {{ data.item.nome_completo }}
          {{ data.item.cod_conta ? ' - ' + data.item.cod_conta : '' }}
        </template>

        <template v-slot:item="data">
          <template>
            <v-list-item-content>
              <v-list-item-title>
                {{ data.item.ddi }} ({{ data.item.ddd }})
                {{ data.item.nr_linha }}
              </v-list-item-title>
              <v-list-item-subtitle v-html="data.item.nome_completo"></v-list-item-subtitle>
            </v-list-item-content>
          </template>
        </template>
      </v-autocomplete>
    </v-col>
  </v-row>
</template>

<script>
import {mapActions, mapMutations, mapState} from "vuex";
export default {
  props: ["field", "model"],
  data() {
    return {
      value: [],
      loading: true,
    };
  },
  async mounted() {
    this.$emit('componentLoading', true);
    this.value = this.model;
    await this.loadLines().catch(error => {
      this.$swal("Oops...", error.message, "error");
    });
    this.loading = false;
    this.$emit('componentLoading', false);
  },
  computed: {
    ...mapState("packages", ["lines"]),
    ...mapState("solicitation", ['solicitation_validate_values']),
  },
  methods: {
    ...mapMutations("forms", ["SET_FORM_EMAIL"]),
    ...mapActions("solicitation", ["updateSolicitationValidateValues"]),
    ...mapActions("packages", ["loadLines"]),
    saveValueText(selected) {
      this.updateSolicitationValidateValues({
        type: this.field.type,
        value: selected || "",
        model: this.field.name
      });
      this.SET_FORM_EMAIL({});
      this.SET_FORM_EMAIL(this.solicitation_validate_values);
    },
    filterLine: function (item, queryText) {
      queryText = queryText.replace(/[\s()\-/]/g, '');
      const regExp = new RegExp(queryText, 'i')
      const phoneNumber = item.ddi + item.ddd + item.nr_linha;
      return (
          regExp.test(phoneNumber) || regExp.test(item.nome_completo.replace(/\s/g, ''))
      );
    },
  }
};
</script>
<style scoped></style>
