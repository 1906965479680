<template>
  <div ref="shadowHost"></div>
</template>

<script>
export default {
  name: "ShadowHtml",
  props: {
    content: {
      type: String,
      required: true,
    },
  },
  mounted() {
    const shadowRoot = this.$refs.shadowHost.attachShadow({ mode: 'open' });
    shadowRoot.innerHTML = this.content;
  },
};
</script>