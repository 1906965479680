<template>
  <v-row class="mx-auto">
    <v-col cols="12" v-if="page !== 'approval' && page !== 'interaction'">
      <v-card outlined :loading="loading" :disabled="loading">
        <v-card-title class="subtitle-1">Suspender Linha</v-card-title>
        <v-card-text>
          <v-btn color="primary" @click="clickAddNewLine()"
            >Selecionar Linha</v-btn
          >
        </v-card-text>
      </v-card>
    </v-col>
    <v-col
      v-for="(line, index) in suspendLine"
      :key="index"
      :cols="suspendLine.length > 1 ? 6 : 12"
    >
      <v-card outlined class="mx-auto">
        <v-card-title
          class="ma-0 pa-0"
          v-if="page !== 'approval' && page !== 'interaction'"
        >
          <v-spacer></v-spacer>
          <v-btn
            icon
            @click="closeLine(index)"
            class="mx-3 mt-2"
            v-if="suspendLine.length > 1"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12">
              <v-autocomplete
                dense
                outlined
                required
                label="Linha"
                v-model="line.value"
                :items="lines.data"
                item-text="nr_linha"
                item-value="id_linha"
                :rules="lineRules"
                :filter="customFilter"
                return-object
                @input="changeLine($event, index)"
              >
                <template v-slot:selection="data">
                  {{ data.item.ddi }} ({{ data.item.ddd }})
                  {{ data.item.nr_linha }} - {{ data.item.nome_completo }}
                  {{ data.item.cod_conta ? ' - ' + data.item.cod_conta : '' }}
                </template>
                <template v-slot:item="data">
                  <template>
                    <v-list-item-content>
                      <v-list-item-title>
                        {{ data.item.ddi }} ({{ data.item.ddd }})
                        {{ data.item.nr_linha }}
                      </v-list-item-title>
                      <v-list-item-subtitle
                        v-html="data.item.nome_completo"
                      ></v-list-item-subtitle>
                    </v-list-item-content>
                  </template>
                </template>
              </v-autocomplete>
              <v-select
                v-if="page == 'interaction' && !loadingSelect"
                :items="setOptions"
                item-text="label"
                item-value="option"
                label="Informar Situação"
                v-model="line.fields[0].value"
                dense
                outlined
              ></v-select>
              <SuspendLineDateFields
                v-if="!loading && page === 'interaction'"
                :type="field.name"
                :index="index"
                @changeDateValues="updateDate"
              />
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { mapActions, mapState } from "vuex";
import SuspendLineDateFields from "./SuspendLineDateFields.vue";

export default {
  components: { SuspendLineDateFields },
  props: ["field", "page"],
  data() {
    return {
      lineSelect: [],
      suspendLine: [],
      loading: true,
      loadingSelect: true,
      required: [v => !!v || "Linha é obrigatório"],
      show: false,
      lineRules: [v => !!v || "Linha é obrigatório"],
      setOptions: [
        { option: 1, label: "Manter pessoa e aparelho vinculado a linha" },
        { option: 2, label: "Desvincular pessoa e aparelho da linha" },
        { option: 3, label: "Desvincular pessoa e baixar aparelho" }
      ]
    };
  },
  async mounted() {
    this.$emit('componentLoading', true);
    let params = {};
    if (this.page === 'approval') {
      params = { showAll: true }
    }

    await this.loadLines(params).catch(error => {
      this.$swal("Oops...", error.message, "error");
    });

    //Salva os pacotes já no storage para enviar caso não tenha sido editado nenhum dado
    this.normalize(this.field);
    this.$emit('componentLoading', false);
  },
  computed: {
    ...mapState("packages", ["lines"])
  },
  methods: {
    ...mapActions("packages", ["loadLines", "loadLinePackages"]),
    ...mapActions("solicitation", [
      "setSuspendLineComponentValues",
      "updateSolicitationValidateSuspendLine"
    ]),

    async normalize(solicitation) {
      if (this.page === "interaction") {
        let interaction = [];
        solicitation.lines.map(line => {
          let data = {
            name: "line",
            type: "line",
            label: "Linha",
            value: line.value,
            fields: [
              {
                type: "suspension_type",
                value: ""
              }
            ]
          };
          interaction.push(data);
        });
        this.suspendLine = interaction;

        this.loading = false;
        this.loadingSelect = false;
      } else {
        let validate = [];
        solicitation.lines.map(line => {
          let data = {
            name: "line",
            type: "line",
            label: "Linha",
            value: line.value
          };
          validate.push(data);
        });
        this.suspendLine = validate;

        this.loading = false;
      }
      this.setSuspendLineComponentValues(this.suspendLine);
    },

    async clickAddNewLine() {
      let newLine = {
        name: "line",
        type: "line",
        label: "Linha",
        value: ""
      };
      this.suspendLine.push(newLine);
    },

    changeLine(line, index) {
      this.suspendLine[index].value = line.id_linha;
      this.setSuspendLineComponentValues(this.suspendLine);
    },

    updateDate(data) {
      let fields = [
        {
          type: "suspension_type",
          value: this.suspendLine[data.index].fields[0].value
        },
        {
          type: "initHour",
          value: data.finishHour
        },
        {
          type: "initDate",
          value: data.initialDate
        }
      ];

      this.suspendLine[data.index].fields = fields;
      this.setSuspendLineComponentValues(this.suspendLine);
    },

    closeLine(index) {
      if (index > -1 && this.suspendLine.length > 1) {
        this.suspendLine.splice(index, 1);
      }
      this.setSuspendLineComponentValues(this.suspendLine);
    },

    customFilter(item, queryText) {
      const textOne = item.nr_linha.toLowerCase();
      const textTwo = item.nome_completo.toLowerCase();
      const searchText = queryText.toLowerCase();
      return (
        textOne.indexOf(searchText) > -1 || textTwo.indexOf(searchText) > -1
      );
    }
  }
};
</script>
<style scoped>
.headerClass {
  white-space: nowrap;
  word-break: normal;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
