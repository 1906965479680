<template>
  <v-list-item>
    <v-list-item-content>
      <v-list-item-title>
        <v-list-item-subtitle>
          {{label}}
        </v-list-item-subtitle>
        {{label_value}}
      </v-list-item-title>
    </v-list-item-content>
  </v-list-item>
</template>

<script>
import { mapActions } from "vuex";
export default {
  props: {
    label: {
      type: String,
      default: ''
    },
    label_value: {
      type: String,
      default: ''
    }
  },
};
</script>
