<template>
  <v-card outlined>
    <v-card-text>
      <v-row class="mx-auto">
        <v-col cols="12" v-if="page !== 'approval' && page !== 'interaction'">
          <v-btn color="primary" @click="clickAddNewLine()"
            >Selecionar Linha</v-btn
          >
        </v-col>
        <v-col
          v-for="(linha, index) in lineSelect.lines"
          :key="index"
          :cols="6"
        >
          <v-card class="mx-auto" outlined v-if="linha">
            <v-card-title class="primary white--text" :title="'Reativação de linha suspensa'">
              <div class="headerClass">
                {{linha.line.ddi ? linha.line.ddi+' ('+linha.line.ddd+') '+linha.line.nr_linha : 'Selecione uma linha' }}
              </div>
              <v-spacer></v-spacer>
              <v-btn
                v-if="
                  page !== 'approval' &&
                  page !== 'interaction' &&
                  lineSelect.lines.length > 1
                "
                icon
                @click="closeLine(index)"
                class="mx-3 mt-2"
              >
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-card-title>
            <v-card-text>
              <v-row>
                <v-col class="col-sm-12 col-md-12 col-lg-12">
                  <div v-if="loading">
                    <v-skeleton-loader type="heading"></v-skeleton-loader>
                  </div>

                  <v-autocomplete
                    v-model="linha.line"
                    :items="lines.data"
                    item-text="nr_linha"
                    item-value="id_linha"
                    outlined
                    dense
                    label="Linha"
                    return-object
                    v-if="!loading"
                    :rules="!!linha.required ? required : []"
                    @change="changeLinePackages($event, linha, index)"
                    :filter="customFilter"
                    :disabled="loading"
                    :loading="loading"
                    :readonly="lineSelect.readonly"
                  >
                    <template v-slot:selection="data">
                      {{ data.item.ddi }} ({{ data.item.ddd }})
                      {{ data.item.nr_linha }} - {{ data.item.nome_completo }}
                      {{ data.item.cod_conta ? ' - ' + data.item.cod_conta : '' }}
                    </template>
                    <template v-slot:item="data">
                      <template>
                        <v-list-item-content>
                          <v-list-item-title>
                            {{ data.item.ddi }} ({{ data.item.ddd }})
                            {{ data.item.nr_linha }}
                          </v-list-item-title>
                          <v-list-item-subtitle
                            v-html="data.item.nome_completo"
                          ></v-list-item-subtitle>
                        </v-list-item-content>
                      </template>
                    </template>
                  </v-autocomplete>
                </v-col>
                <ListPackage
                  v-if="true"
                  :loadingPackage="false"
                  :lineSelected="linha"
                >
                </ListPackage>
              </v-row>
              <SuspendLineDateFields
                v-if="!loading && page === 'interaction'"
                :type="field"
                :index="index"
                :default_date="linha.date.initDate"
                :default_hour="linha.date.initHour"
                @changeDateValues="updateDate"
              />
              <v-checkbox
                  :label="'Deseja vincular a uma nova pessoa?'"
                  v-model="linha.new_person.checkbox"
                  class="mt-0"
                  dense
                  v-if="!loading && page === 'interaction'"
                  @change="saveInStore()"
              ></v-checkbox>
              <!-- Nova Pessoa -->
              <v-autocomplete
                  dense
                  outlined
                  :label="'Nova Pessoa'"
                  v-model="linha.new_person.value"
                  :items="persons.data"
                  item-text="nome_completo"
                  item-value="id_pessoa"
                  v-if="!loading && page === 'interaction' && linha.new_person.checkbox"
                  @change="saveInStore()"
              >
              </v-autocomplete>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import {mapGetters, mapActions, mapState, mapMutations} from "vuex";
import SuspendLineDateFields from "./SuspendLineDateFields.vue";
import ListPackage from "@/components/package/ListPackage";
import axios from "@/axios.config";
import Vue from "vue";

export default {
  components: { SuspendLineDateFields, ListPackage },
  props: ["field", "page"],
  data() {
    return {
      lineSelect: [],
      loadingLinePackages: [],
      loading: true,
      required: [v => !!v || "Linha é obrigatório"]
    };
  },
  async mounted() {
    this.$emit('componentLoading', true);
    this.lineSelect = this.field;

    try {
      await Promise.all([
        this.loadSuspendLines(),
        this.loadPersons()
      ]);

      this.lineSelect.lines.map(val => {
        this.loadingLinePackages.push({ value: false });
      });

      //Salva os pacotes já no storage para enviar caso não tenha sido editado nenhum dado
      this.saveInStore();
    } catch (error) {
      await this.$swal("Oops...", error.message, "error");
    }
    this.loading = false;
    this.$emit('componentLoading', false);
  },
  computed: {
    ...mapState("packages", ["lines"]),
    ...mapGetters({
      linePackages: "packages/getLinePackages"
    }),
    ...mapState("persons", ["persons"]),
    ...mapState("solicitation", ["solicitation_validate_values"])
  },
  methods: {
    ...mapActions("packages", ["loadSuspendLines", "loadLinePackages"]),
    ...mapActions("persons", ["loadPersons"]),
    ...mapMutations("forms", ["SET_FORM_EMAIL"]),
    ...mapActions("interaction", ["setAutomationComponentFields"]),
    ...mapActions("solicitation", ["setAutomationComponentValues"]),
    async clickAddNewLine() {
      this.lineSelect.lines.push({
        packages: [],
        line: {}
      });

      this.loadingLinePackages.push({
        value: false
      });
    },
    async changeLinePackages(selected, linha, index) {
      this.loading = true;
      this.loadingLinePackages[index].value = true;
      if (selected) {
        await this.loadLinePackages({
          id_linha: selected,
          type: 'suspend'
        }).catch(error => {
          this.$swal("Oops...", error.message, "error");
        });
        linha.packages = this.linePackages.data;
      }
      this.saveInStore();
      this.loadingLinePackages[index].value = false;
      this.loading = false;
    },
    saveInStore() {
      if (this.page !== 'interaction') {
        let values_fields = {...this.solicitation_validate_values};
        values_fields.reactivate_line = this.lineSelect;
        this.setAutomationComponentValues({
          automation: "reactivate_line",
          data: values_fields
        });
        this.SET_FORM_EMAIL({});
        this.SET_FORM_EMAIL(this.solicitation_validate_values);
      } else {
        this.setAutomationComponentFields({
          automation: "reactivate_line",
          data: this.lineSelect
        });
      }
    },
    closeLine(index) {
      if (index > -1) {
        this.lineSelect.lines.splice(index, 1);
        this.loadingLinePackages.splice(index, 1);
        this.saveInStore();
      }
    },
    customFilter(item, queryText) {
      queryText = queryText.replace(/[\s()\-/]/g, '');
      const regExp = new RegExp(queryText, 'i')
      const phoneNumber = item.ddi + item.ddd + item.nr_linha;
      return (
          regExp.test(phoneNumber) || regExp.test(item.nome_completo.replace(/\s/g, ''))
      );
    },
    updateDate(data) {
      let fields = {
        initHour: data.finishHour,
        initDate: data.initialDate
      };

      this.lineSelect.lines[data.index].date = fields;
      this.saveInStore();
    }
  }
};
</script>
<style scoped>
.v-card__text,
.v-card__title {
  word-break: normal; /* maybe !important  */
}
.headerClass {
  white-space: nowrap;
  word-break: normal;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
