<template>
  <div>
    <v-card>
      <v-card-text>
        <v-row>
          <v-col cols="6" class="pt-0 pb-0">
            <v-text-field
                label="Condição"
                :value="condition"
                :readonly="true"
                outlined
                dense
            ></v-text-field>
          </v-col>
          <v-col cols="6" class="pt-0 pb-0">
            <v-text-field
                label="Ação"
                :value="action"
                :readonly="true"
                outlined
                dense
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" class="pt-0 pb-0">
            <v-text-field
              label="Assunto"
              :value="subject"
              :readonly="true"
              outlined
              dense
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" class="pt-0 pb-0">
            <v-text-field
                label="Para"
                :value="to"
                :readonly="true"
                outlined
                dense
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row>
          <v-col md="12" cols="12">
            <quill-editor
              :content="body"
              :options="editorOption"
            />
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import { quillEditor } from "vue-quill-editor";
export default {
  components: {
    quillEditor,
  },
  props: {
    condition: {
      type: String,
      default: ""
    },
    action: {
      type: String,
      default: ""
    },
    to: {
      type: String,
      default: ""
    },
    subject: {
      type: String,
      default: ""
    },
    body: {
      type: String,
      default: ""
    },
  },
  data() {
    return {
      loadingInteraction: true,
      loadingDeleteBtn: false,
      dialog: false,
      editorOption: {
        modules: {
          toolbar: [
            [{ size: ["small", false, "large", "huge"] }],
            ["bold", "italic", "underline"],
            ["link", "image"],
            [{ list: "ordered" }, { list: "bullet" }],
            [{ color: [] }, { background: [] }],
            [{ align: [] }],
            ["clean"]
          ]
        }
      },
    };
  },

  async mounted() {
  },
  methods: {
  }
};
</script>

<style scoped>
.btn-mais {
  color: white;
  font-size: 12px;
}

.card-text {
  font-weight: bold;
  font-size: 16px;
}

.description-text {
  font-weight: bold;
  font-size: 13px;
  color: rgba(0, 0, 0, 0.6);
}

.text {
  font-size: 13px;
  font-weight: 400;
}

.align {
  display: flex;
  align-items: center;
}

.chat-container {
  overflow-y: auto;
  padding: 10px;
  border-radius: 10px;
}

.chat-container .titulo {
  font-size: 16px;
  text-align: center;
  font-weight: bold;
}

.chat-container .content {
  padding: 1px 10px 3px 10px;
  background-color: #00838f;
  border-radius: 10px;
  display: inline-block;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 2px 1px -1px rgba(0, 0, 0, 0.12);
  max-width: 50%;
  word-wrap: break-word;
  color: white;
}

.content {
  margin: 0 15px;
}
.content p {
  margin: 0px;
}

.message {
  margin-bottom: 5px;
}

.divider-margin {
  padding: 8px;
}

.message.own {
  text-align: right;
}

.message.own .content {
  background-color: #445e93;
  color: white;
}

.small-date {
  font-size: 11px;
  color: #546e7a;
}

.titulo {
  margin-bottom: 10px;
}

.description-size {
  font-size: 0.875rem;
  line-height: 1.5;
}

.titulo-dialog {
  text-align: center;
  font-size: 20px;
}
@media (max-width: 480px) {
  .chat-container .content {
    max-width: 60%;
  }
}
</style>
