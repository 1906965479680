<template>
  <div>
    <v-textarea
      clearable
      :label="field.label"
      v-model="value"
      outlined
      dense
      @input="saveValueText()"
      @change="saveValue"
      v-if="!loading"
      :rules="!!this.field.required ? required : []"
    ></v-textarea>
  </div>
</template>

<script>
import {mapActions, mapMutations, mapState} from "vuex";

export default {
  props: ["field", "model"],
  data() {
    return {
      value: [],
      loading: true,
      required: [v => !!v || `O campo ${this.field.label} é obrigarório`]
    };
  },
  mounted() {
    this.$emit('componentLoading', true);
    this.value = this.model;
    this.saveValueText();
    this.loading = false;
    this.$emit('componentLoading', false);
  },
  computed: {
    ...mapState("solicitation", ['solicitation_validate_values']),
  },
  methods: {
    ...mapMutations("forms", ["SET_FORM_EMAIL"]),
    ...mapActions("solicitation", ["updateSolicitationValidateValues"]),
    saveValueText() {
      this.updateSolicitationValidateValues({
        text: this.field.label,
        value: this.value,
        model: this.field.name
      });
    },
    saveValue() {
      this.SET_FORM_EMAIL({});
      this.SET_FORM_EMAIL(this.solicitation_validate_values);
    }
  }
};
</script>
<style scoped></style>
