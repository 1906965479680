<template>
  <v-card outlined>
    <v-card-text>
      <v-row class="mx-auto">
        <v-col cols="12" v-if="page !== 'approval' && page !== 'interaction'">
          <v-btn color="primary" @click="clickAddNewLine()"
            >Adicionar linha</v-btn
          >
        </v-col>
        <v-col
          v-for="(linha, index) in lineSelect.lines"
          :key="index"
          class="col-sm-12 col-md-6"
        >
          <v-card class="mx-auto" outlined v-if="linha">
            <v-card-title class="primary white--text">
              <div class="headerClass">
                {{linha.line.ddi ? linha.line.ddi+' ('+linha.line.ddd+') '+linha.line.nr_linha : 'Selecione uma linha' }}
              </div>
              <v-spacer></v-spacer>
              <v-btn
                v-if="page !== 'approval' && page !== 'interaction' && lineSelect.lines.length > 1"
                icon
                @click="closeLine(index)"
                class="mx-3 mt-2"
              >
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-card-title>
            <v-card-text>
              <v-row>
                <v-col class="col-sm-12 col-md-12 col-lg-12 mb-n5">
                  <div v-if="loading[index].value">
                    <v-skeleton-loader type="heading"></v-skeleton-loader>
                  </div>
                  <v-autocomplete
                    v-model="linha.line"
                    :items="lines.data"
                    item-text="nr_linha"
                    outlined
                    dense
                    label="Linha"
                    return-object
                    v-if="!loading[index].value"
                    :rules="!!linha.required ? required : []"
                    @change="changeLinePackages($event, index)"
                    :filter="customFilter"
                    :disabled="loadingPackages"
                    :loading="loadingPackages"
                    :readonly="lineSelect.readonly"
                  >
                    <template v-slot:selection="data">
                      {{ data.item.ddi }} ({{ data.item.ddd }})
                      {{ data.item.nr_linha }} - {{ data.item.nome_completo }}
                      {{ data.item.cod_conta ? ' - ' + data.item.cod_conta : '' }}
                    </template>
                    <template v-slot:item="data">
                      <template>
                        <v-list-item-content>
                          <v-list-item-title>
                            {{ data.item.ddi }} ({{ data.item.ddd }})
                            {{ data.item.nr_linha }}
                          </v-list-item-title>
                          <v-list-item-subtitle
                            v-html="data.item.nome_completo"
                          ></v-list-item-subtitle>
                        </v-list-item-content>
                      </template>
                    </template>
                  </v-autocomplete>
                </v-col>
                <v-col>
                  <DatePicker
                    v-if="!loading[index].value && page === 'interaction'"
                    v-model="linha.finishDate.value"
                    value-type="YYYY-MM-DD"
                    format="DD/MM/YYYY"
                    :placeholder="linha.finishDate.label"
                    style="width: 100%"
                    :disabled="loading[index].value"
                    :title="linha.finishDate.label"
                    @change="saveInStore()"
                  ></DatePicker>
                </v-col>
                <v-col
                  class="col-sm-12 col-md-12 col-lg-12"
                  v-if="linha.line.id_linha"
                >
                  <v-alert
                    border="left"
                    class="text-center"
                    dense
                    type="warning"
                    v-if="loading[index].value"
                    dark
                    >Carregando pacotes da linha!
                  </v-alert>
                  <template
                    v-if="!loading[index].value"
                  >
                    <v-alert
                      border="left"
                      color="indigo"
                      dark
                      dense
                      class="mb-0"
                    >
                      Pacotes da linha:
                    </v-alert>
                    <v-list>
                      <template v-for="(linePackage, index) in linha.packages">
                        <v-list-item :key="linePackage.nome">
                          <v-list-item-content>
                            <v-row class="align-center" dense>
                              <v-col md="10">
                                {{ linePackage.nome }}
                              </v-col>
                              <v-col md="2">
                                <v-switch
                                  class="mt-0"
                                  dense
                                  v-model="linePackage.desativar"
                                  hide-details
                                  inset
                                  :readonly="lineSelect.readonly"
                                  @change="disablePackage(linePackage)"
                                ></v-switch>
                              </v-col>
                            </v-row>
                          </v-list-item-content>
                        </v-list-item>
                        <v-divider
                          v-if="index < linha.packages.length - 1"
                          :key="index"
                        ></v-divider>
                      </template>
                      <template v-if="linha.packages.length < 1">
                        <v-list-item>
                          <v-list-item-content>
                            <v-list-item-title>
                              Não possui
                            </v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </template>
                    </v-list>
                  </template>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import {mapGetters, mapActions, mapState, mapMutations} from "vuex";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import "vue2-datepicker/locale/pt-br";

export default {
  components: { DatePicker },
  props: ["field", "page"],
  data() {
    return {
      lineSelect: [],
      loading: [],
      loadingPackages: false,
      required: [v => !!v || `O campo ${this.field.label} é obrigarório`]
    };
  },
  async mounted() {
    this.$emit('componentLoading', true);
    this.lineSelect = this.field;

    this.lineSelect.lines.map(val => {
      this.loading = [...this.loading, {value: false}];
    });

    let params = {};
    if (this.page === 'approval') {
      params = { showAll: true }
    }

    await this.loadLines(params).catch(error => {
      this.$swal("Oops...", error.message, "error");
    });

    //Salva os pacotes já no storage para enviar caso não tenha sido editado nenhum dado
    this.saveInStore();
    this.$emit('componentLoading', false);
  },
  computed: {
    ...mapState("solicitation", ["solicitation_validate_values"]),
    ...mapState("packages", ["lines"]),
    ...mapGetters({
      linePackages: "packages/getLinePackages"
    })
  },
  methods: {
    ...mapMutations("forms", ["SET_FORM_EMAIL"]),
    ...mapActions("packages", ["loadLines", "loadLinePackages"]),
    ...mapActions("interaction", ["setAutomationComponentFields"]),
    ...mapActions("solicitation", ["setAutomationComponentValues"]),
    async clickAddNewLine() {
      this.loading = [...this.loading, {value: false}];
      this.lineSelect.lines.push({
        line: [],
        packages: [],
      });
    },
    async changeLinePackages(selected, index) {
      this.loading[index].value = true;
      if (selected) {
        await this.loadLinePackages({ id_linha: selected.id_linha }).catch(error => {
          this.$swal("Oops...", error.message, "error");
        });
        this.lineSelect.lines[index].packages = this.linePackages.data;
      } else {
        this.lineSelect.lines[index].line = [];
        this.lineSelect.lines[index].packages = [];
      }
      this.saveInStore();
      this.loading[index].value = false;
    },
    saveInStore() {
      if (this.page !== 'interaction') {
        let values_fields = {...this.solicitation_validate_values};
        values_fields.line_disable_package = this.lineSelect;
        this.setAutomationComponentValues({
          automation: "line_disable_package",
          data: values_fields
        });
        this.SET_FORM_EMAIL({});
        this.SET_FORM_EMAIL(this.solicitation_validate_values);
      } else {
        this.setAutomationComponentFields({
          automation: "line_disable_package",
          data: this.lineSelect
        });
      }
    },
    disablePackage(pacote) {
      pacote.disable = !!pacote.disable;
      this.saveInStore();
    },
    closeLine(index) {
      if (index > -1) {
        this.loading.splice(index, 1);
        this.lineSelect.lines.splice(index, 1);
        this.saveInStore();
      }
    },
    customFilter(item, queryText) {
      queryText = queryText.replace(/[\s()\-/]/g, '');
      const regExp = new RegExp(queryText, 'i')
      const phoneNumber = item.ddi + item.ddd + item.nr_linha;
      return (
          regExp.test(phoneNumber) || regExp.test(item.nome_completo.replace(/\s/g, ''))
      );
    },
  }
};
</script>
<style scoped>
.v-card__text,
.v-card__title {
  word-break: normal; /* maybe !important  */
}
.headerClass {
  white-space: nowrap;
  word-break: normal;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
