<template>
  <div>
    <v-autocomplete
      v-model="value"
      :items="users.data"
      item-text="nome"
      item-value="id_join"
      outlined
      dense
      label="Solicitante"
      :search-input.sync="searchUser"
      return-object
      @change="saveSelected($event)"
      v-if="!loading"
      :rules="!!this.field.required ? required : []"
    >
      <template #no-data>
        <v-list-item v-if="permissionAdmSystem && searchUser != null && searchUser.length > 0" @click="createNewUser(searchUser)">
          <span class="subheading mr-2">Criar usuário</span>{{ searchUser }}
        </v-list-item>
      </template>
    </v-autocomplete>
    <custom-dialog-new-user-component
      v-if="permissionAdmSystem && showDialogNewUser"
      v-model="showDialogNewUser"
      :newUser="newUser"
      :listUsers="users.data"
      @close="showDialogNewUser = !showDialogNewUser"
      @newUserData="newUserData"
    ></custom-dialog-new-user-component>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
import CustomDialogNewUserComponent from "@/components/forms/CustomRequesterComponent/CustomDialogNewUserComponent.vue";
export default {
  components: { CustomDialogNewUserComponent },
  props: ["field", "model", "is_adm"],
  data() {
    return {
      value: [],
      loading: true,
      required: [v => !!v || `O campo ${this.field.name} é obrigarório`],
      searchUser: '',
      showDialogNewUser: false,
      newUser: '',
      listData: [],
      permissionAdmSystem: false,
    };
  },
  async mounted() {
    await this.loadUsers().catch(error => {
      this.$swal("Oops...", error.message, "error");
    });

    this.value = this.model;
    this.loading = false;
    this.updateSolicitationValidateValues({
      text: this.field.label,
      value: this.model,
      model: this.field.name
    });

    if (this.is_adm) {
      this.permissionAdmSystem = true;
    }
  },
  methods: {
    ...mapActions("solicitation", ["updateSolicitationValidateValues"]),
    ...mapActions("user", ["loadUsers"]),
    ...mapMutations("forms", ["SET_FORM_EMAIL"]),

    newUserData(data) {
      this.value = data;
      this.saveSelected(data);
    },

    createNewUser(user) {
      this.showDialogNewUser = true;
      this.newUser = user;
      this.searchUser = '';
    },

    saveSelected(selected) {
      if (selected) {
        this.updateSolicitationValidateValues({
          text: selected.nome ? selected.nome : "",
          value: selected.id_join ? selected.id_join : "",
          model: this.field.name
        });
      }
      this.SET_FORM_EMAIL({});
      this.SET_FORM_EMAIL(this.solicitation_validate_values);
    }
  },
  computed: {
    ...mapState("user", ["users"]),
    ...mapState("solicitation", ["solicitation_validate_values"]),
  }
};
</script>
<style scoped></style>
