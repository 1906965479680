<template>
  <div>
    <v-autocomplete
      v-model="value"
      :items="companies"
      item-text="nome"
      item-value="id_join"
      outlined
      dense
      label="Empresa"
      return-object
      @change="saveSelected($event)"
      v-if="!loading"
      :rules="!!this.field.required ? required : []"
    ></v-autocomplete>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
export default {
  props: ["field", "model"],
  data() {
    return {
      value: [],
      loading: true,
      required: [v => !!v || `O campo ${this.field.label} é obrigarório`]
    };
  },
  async mounted() {
    await this.loadCompanies().catch(error => {
      this.$swal("Oops...", error.message, "error");
    });
    this.value = this.model.toString();

    await this.updateSolicitationValidateValues({
      text: this.field.label,
      value: this.value,
      model: this.field.name
    });
    this.loading = false;
  },
  methods: {
    ...mapActions("solicitation", ["updateSolicitationValidateValues"]),
    ...mapActions("company", ["loadCompanies"]),
    ...mapMutations("forms", ["SET_FORM_EMAIL"]),

    saveSelected(selected) {
      if (selected) {
        this.updateSolicitationValidateValues({
          text: selected.text ? selected.text : "",
          value: selected.value ? selected.value : "",
          model: this.field.name
        });
      }
      this.SET_FORM_EMAIL({});
      this.SET_FORM_EMAIL(this.solicitation_validate_values);
    }
  },
  computed: {
    ...mapState("company", ["companies"]),
    ...mapState("solicitation", ["solicitation_validate_values"]),
  }
};
</script>
<style scoped></style>
