<template>
  <div>
    <v-autocomplete
      v-model="value"
      :items="field.options"
      item-text="text"
      outlined
      dense
      :label="field.label"
      return-object
      @change="saveSelected($event)"
      v-if="!loading"
      :rules="!!this.field.required ? required : []"
    ></v-autocomplete>
  </div>
</template>

<script>
import {mapActions, mapMutations, mapState} from "vuex";

export default {
  props: ["field", "model"],
  data() {
    return {
      value: [],
      loading: true,
      required: [v => !!v || `O campo ${this.field.label} é obrigarório`]
    };
  },
  mounted() {
    this.$emit('componentLoading', true);
    this.value = this.model;
    this.loading = false;
    this.$emit('componentLoading', false);
  },
  computed: {
    ...mapState("solicitation", ['solicitation_validate_values']),
  },
  methods: {
    ...mapMutations("forms", ["SET_FORM_EMAIL"]),
    ...mapActions("solicitation", ["updateSolicitationValidateValues"]),
    saveSelected(selected) {
      if (selected) {
        this.updateSolicitationValidateValues({
          text: selected.text ? selected.text : "",
          value: selected.value ? selected.value : "",
          model: this.field.name
        });

        this.SET_FORM_EMAIL({});
        this.SET_FORM_EMAIL(this.solicitation_validate_values);
      }
    }
  }
};
</script>
<style scoped></style>
