<template>
  <v-dialog :value="value" @input="$event => $emit($event)" persistent max-width="600px">
    <v-card>
      <v-card-title>
        <span class="text-h5">Criar novo Usuário</span>
      </v-card-title>

      <v-card-text>
        <v-container>
          <v-form ref="validForm">
            <v-row>
              <v-col cols="12">
                <v-text-field label="Nome*" disabled dense outlined :value="newUser" />
              </v-col>

              <v-col cols="12">
                <v-text-field label="E-mail*" v-model="form.email" type="email" dense outlined
                  :rules="[v => !!v || 'Email obrigatório', v => /.+@.+\..+/.test(v) || 'Email inválido']"/>
              </v-col>

              <v-col cols="12">
                <v-text-field label="Senha*" v-model="form.password" :type="showPassword ? 'text' : 'password'" dense outlined
                  :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'" @click:append="showPassword = !showPassword" :rules="[v => !!v || 'Senha obrigatório', v => (v && v.length >= 5) || 'Minimo 5 caracteres']"/>
              </v-col>

              <v-col cols="12">
                <v-text-field label="Confirmar Senha*" v-model="form.check_password" :type="showCheckPassword ? 'text' : 'password'" :append-icon="showCheckPassword ? 'mdi-eye' : 'mdi-eye-off'"
                  @paste.prevent @click:append="showCheckPassword = !showCheckPassword" dense outlined :rules="[v => !!v || 'Confirmar Senha obrigatória',v => v === form.password || 'As senhas não coincidem']" />
              </v-col>

              <v-col cols="12">
                <v-autocomplete :items="groups" label="Grupo*" v-model="form.group" dense outlined
                  :disabled="loadingGroup" :loading="loadingGroup" item-text="descricao" item-value="id_grupo" :rules="[v => !!v || 'Grupo obrigatório']" />
              </v-col>
            </v-row>
          </v-form>
        </v-container>
        <small>* Campos obrigatórios</small>
      </v-card-text>

      <v-card-actions>
        <v-spacer />

        <v-btn color="blue darken-1" :disabled="meta.sending" text @click="close()">
          Fechar
        </v-btn>

        <v-btn color="blue darken-1" :disabled="isLoading" text @click="save()">
          Salvar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from "@/axios.config";
import { mapActions, mapState } from "vuex";

export default {
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    newUser: {
      type: String,
      default: undefined
    },
    listUsers: {
      type: Array,
      default: () => [],
    },
  },
  data: function () {
    return {
      form: {
        name: undefined,
        email: undefined,
        password: undefined,
        check_password: undefined,
        group: undefined,
      },
      meta: {
        loading: true,
        sending: false,
      },
      loadingGroup: true,
      showPassword: false,
      showCheckPassword: false,
      groups: []
    }
  },

  async mounted() {
    await this.loadGroup().catch(error => {
      this.$swal("Oops...", error.message, "error");
    });
    this.groups = this.group_list;

    this.meta.loading = false;
    this.loadingGroup = false;
  },

  methods: {
    ...mapActions("group", ["loadGroup"]),
    ...mapActions("user", ["saveUserJoin"]),

    close: function () {
      if (this.meta.sending) {
        return;
      }
      this.$emit('close');
    },

    newUserData: function () {
      this.$emit("newUserData", this.user_join);
    },

    save: async function () {
      if (this.$refs.validForm.validate()) {
        this.meta.sending = true;
        this.form.name = this.newUser;

        try {
          await this.saveUserJoin(this.form);

          this.listUsers.push(this.user_join);
          this.meta.sending = false;
          this.newUserData();
          this.close();
        } catch (error) {
          await this.$swal("Oops ...", error.message, "error");
          this.meta.sending = false;
        }
      }
    },
  },

  computed: {
    ...mapState("group", ["group_list"]),
    ...mapState("user", ["user_join"]),

    isLoading: function () {
      return this.meta.loading || this.meta.sending
    }
  },
}
</script>

<style scoped></style>