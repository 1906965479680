<template>
  <v-card>
    <v-card-title>Linha do tempo da solicitação</v-card-title>
    <v-card-text class="text--primary">
      <v-timeline
        dense
        v-for="interaction in interactions.data"
        :key="interaction.id"
      >
        <v-timeline-item
          v-if="showHistoric(interaction.status_id)"
          small
          :color="interaction.color"
        >
          <v-row class="pt-1" >
            <v-col class="status">
              <strong>{{ interaction.status_name }}</strong>
              <div class="caption">
                {{ interaction.user }}
                <br />
                {{ interaction.created_at_format }}
              </div>
            </v-col>
          </v-row>
        </v-timeline-item>
      </v-timeline>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapState } from "vuex";
import {SOLICITATION_STATUS} from "@/helpers/constants";
export default {
  data() {
    return {};
  },
  computed: {
    ...mapState("interaction", ["interactions","showNotification"])
  },
  methods: {
    showHistoric(status_id) {
      return status_id !== SOLICITATION_STATUS.ENVIO_NOTIFICACAO ? true : this.showNotification;
    },
  }
};
</script>

<style scoped>
.v-card {
  height: 100%;
}

.v-card__text {
  word-break: normal; /* maybe !important  */
}

.v-card__title {
  width: 100%;
  display: flex;
  justify-content: center;
  color: #4d638c;
}

@media (max-width: 600px) {
  .v-sheet.v-card {
    box-shadow: none;
    -webkit-box-shadow: none;
  }

  .v-card {
    padding: 0px !important;
    margin: 0px;
    border-radius: 0px;
    border: none;
  }
  .v-card__title {
    font-size: 0.8rem;
    padding: 0px 0px 10px 0px;
  }
  .v-card__text,
  .text--primary {
    padding: 0px 10px 0px 0px;
  }

  .status {
    font-size: 0.8rem;
  }

  .v-timeline--dense >>> .v-timeline-item__body {
    max-width: calc(100% - 50px);
  }

  .v-timeline-item >>> .v-timeline-item__divider {
    min-width: 50px !important;
  }

  .v-application--is-ltr .v-timeline--dense:not(.v-timeline--reverse):before {
    left: calc(25px - 1px);
    right: initial;
  }
}

@media (min-width: 601px) {
  .v-card {
    border: thin solid rgba(0, 0, 0, 0.12);
  }

  .v-card__title {
    font-size: 1.5rem;
  }

  .v-sheet.v-card {
    box-shadow: none;
  }
}
</style>
