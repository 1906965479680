<template>
  <div>
    <v-menu
      ref="menu1"
      v-model="menu"
      :close-on-content-click="false"
      transition="scale-transition"
      offset-y
      max-width="290px"
      min-width="auto"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-text-field
          v-model="value"
          label="Insira a data"
          persistent-hint
          prepend-icon="mdi-calendar"
          readonly
          v-bind="attrs"
          v-on="on"
          dense
          outlined
          @change="computedDateFormatted"
        ></v-text-field>
      </template>
      <v-date-picker
        v-model="date"
        @input="menu = false"
        @change="saveValueText"
      ></v-date-picker>
    </v-menu>
  </div>
</template>

<script>
import {mapActions, mapMutations, mapState} from "vuex";

export default {
  props: ["field", "model"],
  data: vm => ({
    date: new Date().toISOString().substr(0, 10),
    dateFormatted: vm.formatDate(new Date().toISOString().substr(0, 10)),
    menu: false,
    value: ""
  }),

  computed: {
    ...mapState("solicitation", ['solicitation_validate_values']),
    computedDateFormatted() {
      return this.formatDate(this.date);
    }
  },
  mounted() {
    this.$emit('componentLoading', true);
    this.value = this.model;
    this.$emit('componentLoading', false);
  },

  watch: {
    date() {
      this.dateFormatted = this.formatDate(this.date);
    }
  },

  methods: {
    ...mapMutations("forms", ["SET_FORM_EMAIL"]),
    ...mapActions("solicitation", ["updateSolicitationValidateValues"]),

    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return (this.value = `${day}/${month}/${year}`);
    },

    saveValueText(selected) {
      this.updateSolicitationValidateValues({
        text: selected || "",
        value: selected || "",
        model: this.field.name
      });

      this.SET_FORM_EMAIL({});
      this.SET_FORM_EMAIL(this.solicitation_validate_values);
    }
  }
};
</script>
<style scoped></style>
